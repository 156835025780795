<div *ngIf="withBanner" class="banner">
	<h4>{{ 'rates.importNote' | translate }}</h4>
	<img alt="excel-icon" src="assets/img/excel-illustration.png">
</div>

<alaris-stepper #stepper *alarisLet="(ratesImportHistory$ | async)?.length as ratesImportHistory"
				[alarisLoading]="loading$"
				orientation="vertical">
	<alaris-step [completed]="!firstStepDisabled">
		<alaris-step-label>
			{{ 'rates.import.step1' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			<div class="content">
				<alaris-file-loader (fileListChanges)="addFiles($event)"
									[exportInternally]="true"
									[files]="fileInfo ? [fileInfo] : []"
									[multiple]="false"
									data-testid="rateFile"
									fileTypes=".csv,.xls,.xlsx,.numbers">
				</alaris-file-loader>
				<div class="next">
					<button (click)="stepper.next()" [disabled]="firstStepDisabled" alaris-button
							bType="primary" data-testid="toSecondStepBtn" size="lg">
						{{ 'gl.continue' | translate }}
					</button>
				</div>
			</div>
		</alaris-step-content>
	</alaris-step>
	<alaris-step (closed)="saveParsedColumns()"
				 [completed]="!secondStepDisabled"
				 [disabled]="firstStepDisabled">
		<alaris-step-label>
			{{ 'rates.import.step2' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			<div *ngIf="stepper.selectedIndex === 1"
				 [formGroup]="paramsForm"
				 class="content">
				<ng-container *ngIf="predefinedSubscriptionId === undefined">
					<b>{{ 'gl.product' | translate }}</b>
					<span class="note">{{ 'rates.import.productNote' | translate }}</span>
					<div class="in-row">
						<alaris-select2 [alarisLoading]="ratesImportHistoryService.loading$"
										[displayWith]="displayPartners"
										[label]="'fc.vendorProduct' | translate"
										[placeholder]="'fc.vendorProduct' | translate"
										class="xlWidth" data-testid="vendorProductSelect"
										formControlName="subscriptionId" selectedLabel="name">
							<alaris-select-filter2 *ngIf="((vsService.list$ | async)?.length ?? 0) > 5"
												   [formControl]="filterProductControl"
												   data-testid="vendorProductFilter">
							</alaris-select-filter2>
							<alaris-options2 *ngFor="let opt of filterProductList$ | async"
											 [attr.data-testid]="'vendorProductOption/' + opt.name" [value]="opt.id">
								{{ opt.name }}
							</alaris-options2>
						</alaris-select2>
						<button (click)="openProductPanel()"
								alaris-button bType="outline" data-testid="addProductBtn" size="xl">
							<alaris-icon class="in-outline" name="icon-plus"></alaris-icon>
							{{ 'products.addEntry' | translate }}
						</button>
					</div>
				</ng-container>
				<ng-container *ngIf="ratesImportHistory">
					<b>{{ 'rates.import.mergeOption' | translate }}</b>
					<fieldset>
						<alaris-radio [attr.data-testid]="'mergeTypeRadio/' + MergeType.UPDATE"
									  [id]="'update'"
									  [value]="MergeType.UPDATE"
									  formControlName="mergeType" name="details">
							{{ 'actions.update' | translate }}
						</alaris-radio>
						<span class="note shift">{{ 'rates.import.updateNote' | translate }}</span>
						<alaris-radio [attr.data-testid]="'mergeTypeRadio/' + MergeType.CLOSE" [id]="'replace'"
									  [value]="MergeType.CLOSE"
									  formControlName="mergeType"
									  name="details">
							{{ 'actions.close' | translate }}
						</alaris-radio>
						<span class="note shift">{{ 'rates.import.closeNote' | translate }}</span>
					</fieldset>
				</ng-container>

				<small>{{ 'rates.import.mappingNote' | translate }}</small>
				<div class="hint">
					<alaris-icon name="icon-info"></alaris-icon>
					{{
						(paramsForm.controls.mergeType.value === MergeType.UPDATE ?
							'rates.import.requiredFieldsForUpdate' : 'rates.import.requiredFieldsForClose') | translate
					}}
				</div>
				<alaris-parsed-file-table (columns)="setColumns($event)"
										  [fields]="fieldsForMapping"
										  [initialColumns]="initialColumns"
										  [rows]="rows">
				</alaris-parsed-file-table>
				<div *ngIf="paramsForm.controls.dateFormat.enabled" class="in-row">
					<alaris-select2 [label]="('fc.dateFormat' | translate)"
									[placeholder]="'fc.dateFormat' | translate"
									[stateAddonEnabled]="{ reset: true, state: true }"
									class="xlWidth"
									data-testid="dateFormatSelect" formControlName="dateFormat">
						<alaris-input
							(click)="$event.stopPropagation()"
							[formControl]="dateTimeControl"
							[placeholder]="'fc.dateFormatCustom' | translate" data-testid="dateFormatCustomInput">
							<label>
								{{
									dateTimeControl.valid
										? ('fc.dateFormatCustom' | translate)
										: ('errors.dataTimeFormat' | translate)
								}}
							</label>
							<div (click)="setDateTimeFormat()"
								 [class.disabled]="dateTimeControl.invalid || !dateTimeControl.value"
								 class="addon" data-testid="setDateTimeFormatBtn"
								 extra>
								<alaris-icon name="icon-add"></alaris-icon>
							</div>
						</alaris-input>
						<alaris-options2 *ngFor="let opt of DateTimeFormats"
										 [attr.data-testid]="'dateFormatOption/' + opt" [value]="opt">
							{{ opt }}
						</alaris-options2>
					</alaris-select2>
					<alaris-alert style="padding: 0.5rem; align-items: center;">
						<ng-container subTitle>
							<div>
								<div>{{ 'fc.dateTimeFormatNote1' | translate }}</div>
								<div>{{ 'fc.dateTimeFormatNote2' | translate }}</div>
							</div>
						</ng-container>
					</alaris-alert>
					<alaris-alert bg="primary" type="info">
						<ng-container subTitle>
							{{ 'rates.importRatesTimeNote' | translate }}
						</ng-container>
					</alaris-alert>
				</div>
				<b>{{ 'gl.availability' | translate }}</b>
				<span class="note in-row">
				{{
						(paramsForm.controls.mergeType.value === MergeType.UPDATE ?
							'rates.import.startDateNote' : 'rates.import.endDateNote') | translate
					}}
					<alaris-icon [alarisTooltip]="'rates.import.timeNoteHint' | translate"
								 name="icon-info"></alaris-icon>
				</span>
				<div class="in-row">
					<alaris-scheduled [control]="paramsForm.controls.defaultDate" [systemTime]="true"
									  class="xxlWidth"></alaris-scheduled>
				</div>
				<div class="next">
					<button (click)="stepper.next()"
							[disabled]="secondStepDisabled"
							alaris-button bType="primary" data-testid="toThirdStepBtn" size="lg">
						{{ 'gl.continue' | translate }}
					</button>
				</div>
			</div>
		</alaris-step-content>
	</alaris-step>
	<alaris-step [completed]="!thirdStepDisabled"
				 [disabled]="firstStepDisabled || secondStepDisabled">
		<alaris-step-label>
			{{ 'rates.import.step3' | translate }}
		</alaris-step-label>
		<alaris-step-content>
			<div *ngIf="stepper.selectedIndex === 2" class="content">
				<app-rates-analysis (analysisDoneCorrect)="isAnalysisStepSuccess = $event"
									[predefinedSubscriptionId]="predefinedSubscriptionId"
									[requestParams]="requestParams">
				</app-rates-analysis>
				<div class="next">
					<button (click)="import()" [disabled]="thirdStepDisabled" alaris-button
							bType="primary"
							data-testid="importBtn" size="lg">
						{{ 'rates.import.importRates' | translate }}
					</button>
				</div>
			</div>
		</alaris-step-content>
	</alaris-step>
</alaris-stepper>
