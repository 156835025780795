import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, EMPTY, map, of, ReplaySubject, startWith, switchMap, takeUntil } from 'rxjs';

import { Package, PackCostRequest, Plan, Subscription } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import {
	DecisionMode,
	ImportStatus,
	MessagePurpose,
	SubscriptionType,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { ContractCompany } from '@campaign-portal/namespace/entities/contract-company/specs';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { Currency } from '@campaign-portal/namespace/entities/currency/specs';
import { RatesImportRequest } from '@campaign-portal/namespace/entities/rates/specs';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { UpdateResponse } from '@campaign-portal/namespace/common/implementations';
import { RatesImportHistory } from '@campaign-portal/namespace/entities/rates-import-history/specs';
import { FilterType, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';

import {
	AlarisBalanceService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisMultiSelectDisplayWithFn,
	AlarisProfileService,
	CustomValidators,
	Day,
	EditPanelWidth,
	filterWildcardData
} from '@campaign-portal/components-library';

import { AP_PERMISSIONS } from '@helpers/types/permissions';
import { CurrencyService } from '@helpers/services/currency.service';
import { PackageEntity, SubscriptionGroupEntity } from '@helpers/types/app.classes-interfaces';
import { RatesService } from '@helpers/services/rates.service';
import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { AuthGuardService } from '../../../../auth/auth.guard';
import { PartnersService } from '../../../partners/partners.service';
import { ContractCompaniesService } from '../../../settings/contract-companies/contract-companies.service';
import { SubscriptionsService } from '../subscriptions.service';
import { RatesImportPanelComponent } from './rates-import-panel/rates-import-panel.component';
import { RatesImportHistoryService } from '@helpers/components/import-history/rates-import-history.service';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { SubscriptionGroupsService } from '../../subscription-groups/subscription-groups.service';
import { SubscriptionGroup } from '@campaign-portal/namespace/entities/subscription-groups/specs';
import { EditSubscriptionGroupComponent } from '../../subscription-groups/edit-subscription-group/edit-subscription-group.component';
import { OwnerService } from '@helpers/services/owner.service';

interface SubscriptionControls {
	id: FormControl<Id>;
	name: FormControl<string>;
	description: FormControl<string>;
	isActive: FormControl<boolean>;
	type: FormControl<SubscriptionType | null>;
	trafficType: FormControl<TrafficType | null>;
	settings: FormGroup<{
		viberSettings: FormGroup<{
			messagePurpose: FormControl<MessagePurpose>;
		}>;
	}>;
	contractCompanies: FormControl<Id<exist>[] | null>;
	personalFor: FormControl<Id<exist>[] | null>;
	groupId: FormControl<Id<exist> | null>;
	billingMode: FormControl<DecisionMode | null>;
	currencyId: FormControl<Id>;

}

interface PackControls {
	packSettings: FormGroup<{
		packPrice: FormControl<number | null>;
		activeFrom: FormControl<Date | null>;
		activeTo: FormControl<Date | null>;
		isPromo: FormControl<boolean>;
	}>;
	messagesTotal: FormControl<number | null>;
	availableCountryNetList: FormControl<Id<exist>[] | null>;
}


@Component({
	selector: 'app-subscription-details',
	templateUrl: './subscription-details.component.html',
	styleUrls: ['./subscription-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionDetailsComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly viberPriorityComponent = ViberPriorityComponent;
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	readonly loading$ = this.allowedDeactivation.pipe(map(resp => !resp));

	readonly AP_PERMISSIONS = AP_PERMISSIONS;
	readonly SubscriptionType = SubscriptionType;
	readonly TrafficType = TrafficType;
	readonly ImportStatus = ImportStatus;
	readonly MessagePurpose = MessagePurpose;
	readonly Day = Day;

	readonly billingModes = Object.values(DecisionMode);
	readonly planTypes: SubscriptionType[] = [SubscriptionType.PACK, SubscriptionType.PLAN, SubscriptionType.RESELLER];

	readonly subscription!: Package | Plan;
	readonly subscriptionForm!: FormGroup<SubscriptionControls>;
	readonly packageForm!: FormGroup<PackControls>;

	readonly filterCcControl = new FormControl('');
	readonly filterPartnersControl = new FormControl('');
	readonly filterGroupsControl = new FormControl('');
	readonly filterCurrencyControl = new FormControl('');
	filterCCList: ContractCompany<exist>[] = [];
	filterPartnersList: Partner<exist>[] = [];
	filterCurrencyList: Currency<exist>[] = [];
	filterGroupsList: SubscriptionGroup<exist>[] = [];

	minPackPrice?: number;
	readonly package!: Package;

	readonly _maxNameLength = 40;
	readonly errors = [
		{
			key: 'maxlength',
			value: this.lService.translate('errors.maxLength', { amount: `${this._maxNameLength}` })
		}
	];

	activeImportHistory: RatesImportHistory[] = [];
	readonly ratesImportParams = new BehaviorSubject<RatesImportRequest | undefined>(undefined);

	protected readonly ngUnsubscribe = new ReplaySubject<boolean>(1);

	constructor(
		public readonly subscriptionService: SubscriptionsService,
		public readonly subGroupsService: SubscriptionGroupsService,
		public readonly currencyService: CurrencyService,
		public readonly partnerService: PartnersService,
		public readonly ccService: ContractCompaniesService,
		public readonly bs: AlarisBalanceService,
		public readonly importHistoryService: RatesImportHistoryService,
		public readonly isOwner: OwnerService,
		private readonly ratesService: RatesService,
		private readonly lService: AlarisLanguageService,
		private readonly router: Router,
		private readonly authGuard: AuthGuardService,
		private readonly countriesNetworks: CountriesNetworksService,
		private readonly fileService: AlarisFilesService,
		private readonly profile: AlarisProfileService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly cd: ChangeDetectorRef
	) {
		super();
		const state = this.router.getCurrentNavigation()?.extras?.state;
		if ( !state ) {
			this.router.navigate(this.authGuard.parentRoute.getValue()?.url
				.map(item => item.path) ?? []);
		} else {
			this.authGuard.currentRouteData.next(
				{
					...this.authGuard.currentRouteData.getValue(),
					title: this.profile.allowed([AP_PERMISSIONS.SUBSCR_E])
						? state.id
							? 'subscriptions.editSubscription'
							: 'subscriptions.addSubscription'
						: 'subscriptions.details'
				}
			);

			this.subscription = state as Package | Plan;
			this.subscriptionForm = new FormGroup({
				id: new FormControl(this.subscription.id || null),
				name: new FormControl(
					this.subscription.name || '',
					{ nonNullable: true, validators: [Validators.required, Validators.maxLength(this._maxNameLength)] }
				),
				description: new FormControl(
					this.subscription.description || '',
					{ nonNullable: true }
				),
				isActive: new FormControl<boolean>(
					this.subscription.isActive ?? true,
					{ nonNullable: true }
				),
				type: new FormControl(
					{
						value: this.subscription.type || null,
						disabled: !!this.subscription.partners?.length
					},
					{ validators: Validators.required }
				),
				trafficType: new FormControl(
					{
						value: this.subscription.trafficType || null,
						disabled: !!this.subscription.partners?.length
					},
					{ validators: Validators.required }
				),
				settings: new FormGroup({
					viberSettings: new FormGroup({
						messagePurpose: new FormControl(
							{
								value: this.subscription.settings?.viberSettings.messagePurpose ||
									MessagePurpose.TRANSACTION,
								disabled: !!this.subscription.partners?.length
							},
							{ nonNullable: true }
						)
					})
				}),
				contractCompanies: new FormControl(
					this.subscription.contractCompanies || [],
					{ validators: CustomValidators.requiredArrayOrNull }
				),
				personalFor: new FormControl<Id<exist>[]>(this.subscription.personalFor || []),
				groupId: new FormControl<Id<exist> | null>(this.subscription.groupId ?? null),
				billingMode: new FormControl(
					{
						value: this.subscription.billingMode || null,
						disabled: !!this.subscription.partners?.length
					},
					{ validators: Validators.required }
				),
				currencyId: new FormControl<Id>(
					{
						value: this.subscription.currencyId || null,
						disabled: !!this.subscription.partners?.length
					},
					{ validators: Validators.required }
				)
			});

			const sub = this.subscription as Package;
			this.packageForm = new FormGroup({
				messagesTotal: new FormControl(
					{
						value: sub.messagesTotal || null,
						disabled: !!this.subscription.partners?.length
					},
					{ validators: Validators.required }
				),
				packSettings: new FormGroup({
					packPrice: new FormControl(
						{
							value: (sub.packSettings)?.packPrice ?? null,
							disabled: !!this.subscription.partners?.length || sub.packSettings?.isPromo
						},
						{
							validators: (sub.packSettings)?.isPromo
								? []
								: [Validators.required, CustomValidators.positiveNumber]
						}
					),
					activeFrom: new FormControl(
						{
							value: (sub.packSettings)?.activeFrom ?
								new Date((sub.packSettings)?.activeFrom) : null,
							disabled: !!this.subscription.partners?.length
						},
						{ validators: Validators.required }
					),
					activeTo: new FormControl(
						{
							value: (sub.packSettings)?.activeTo ?
								new Date((sub.packSettings)?.activeTo || '') : null,
							disabled: !!this.subscription.partners?.length
						}
					),
					isPromo: new FormControl<boolean>(
						{
							value: (sub.packSettings)?.isPromo || false,
							disabled: !!this.subscription.partners?.length
						},
						{ nonNullable: true }
					)
				}, [CustomValidators.moreThan('activeFrom', 'activeTo')]),
				availableCountryNetList: new FormControl<Id<exist>[] | null>(
					sub.availableCountryNetList !== undefined
						? sub.availableCountryNetList === null
							? this.countriesNetworks.list.map(i => i.id)
							: sub.availableCountryNetList
						: [],
					{ validators: CustomValidators.requiredArrayOrNull }
				)
			});
			if ( this.subscriptionForm.controls.type.value !== SubscriptionType.PACK ) {
				this.packageForm.disable();
			}
			if ( this.subscriptionForm.controls.trafficType.value !== TrafficType.VIBER ) {
				this.subscriptionForm.controls.settings.disable();
			}
			this.package = new PackageEntity(null, this.packageForm.controls.availableCountryNetList.value);
		}
	}

	get disabled(): boolean {
		if ( this.subscriptionForm.controls.type.value === SubscriptionType.PACK ) {
			return this.subscriptionForm.invalid || this.packageForm.invalid ||
				!(this.subscriptionForm.dirty || this.packageForm.dirty);
		}
		return this.subscriptionForm.invalid || !this.subscriptionForm.dirty;
	}

	get warnAboutPrice(): boolean {
		return this.minPackPrice
			? (this.packageForm.controls.packSettings.controls.packPrice.value as number ?? 0) < this.minPackPrice
			: false;
	}

	ngOnInit(): void {
		combineLatest([
			this.ccService.list$,
			this.partnerService.list$,
			this.currencyService.list$,
			this.subGroupsService.list$,
			this.loading$
		]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([cc, _partners, currencies, groups, loading]) => {
			if ( !loading ) {
				this.filterCCList = [...cc];
				this.filterPartnersList = this.filterPartners();
				this.filterCurrencyList = [...currencies];
				this.filterGroupsList = [...groups];
			}
		});

		this.filterCcControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCCList = filterWildcardData(value, this.ccService.list, 'name');
			});

		this.filterPartnersControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.filterPartnersList = this.filterPartners();
			});

		this.filterCurrencyControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterCurrencyList = filterWildcardData(value, this.currencyService.list, 'name');
			});

		this.filterGroupsControl.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.filterGroupsList = filterWildcardData(value, this.subGroupsService.list, 'name');
			});

		combineLatest([
			this.packageForm.controls.availableCountryNetList.valueChanges,
			this.packageForm.controls.messagesTotal.valueChanges,
			this.packageForm.controls.packSettings.controls.isPromo.valueChanges,
			this.subscriptionForm.controls.trafficType.valueChanges,
			this.subscriptionForm.controls
				.settings.controls
				.viberSettings.controls
				.messagePurpose.valueChanges.pipe(startWith(MessagePurpose.PROMOTION))
		]).pipe(
			switchMap(([countryNet, total, promo, traffic, messagePurpose]) => {
				this.minPackPrice = undefined;
				if ( (countryNet?.length || countryNet === null) && total && traffic && !promo ) {
					const params: PackCostRequest = {
						Data: {
							Ids: countryNet,
							messagesTotal: total,
							trafficType: traffic
						}
					};

					if ( traffic === TrafficType.VIBER ) {
						params.Data.messagePurpose = messagePurpose;
					}

					return this.subscriptionService.packCost(params);
				}
				return EMPTY;
			}),
			takeUntil(this.ngUnsubscribe)
		)
			.subscribe(
				res => {
					if ( res.Success ) {
						this.minPackPrice = res.Data.at(0)?.min;
						this.cd.detectChanges();
					}
				}
			);

		this.subscriptionForm.controls.type.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				this.subscriptionForm.controls.personalFor.setValue([]);
				this.filterPartnersList = this.filterPartners();

				if ( value === SubscriptionType.PACK ) {
					this.packageForm.enable();
				} else {
					this.packageForm.disable();
				}
			});

		this.subscriptionForm.controls.trafficType.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value === TrafficType.VIBER ) {
					this.subscriptionForm.controls.settings.enable();
				} else {
					this.subscriptionForm.controls.settings.disable();
				}
			});

		combineLatest([
			this.subscriptionService.loading$,
			this.ratesService.loading$
		])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((resp) => {
				this.allowedDeactivation.next(!(resp[0] || resp[1]));
			});

		if ( this.subscriptionForm.controls.contractCompanies?.value?.length ) {
			this.subscriptionForm.controls.personalFor.enable();
		} else {
			this.subscriptionForm.controls.personalFor.disable();
		}

		if ( !this.profile.allowed([AP_PERMISSIONS.SUBSCR_E]) ) {
			this.packageForm.disable({ emitEvent: false });
			this.subscriptionForm.disable({ emitEvent: false });
		}

		this.subscriptionForm.controls.contractCompanies.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value?.length || value === null ) {
					this.subscriptionForm.controls.personalFor.enable();
				} else {
					this.subscriptionForm.controls.personalFor.disable();
				}
				this.subscriptionForm.controls.personalFor.setValue([]);
				this.filterPartnersControl.setValue('');
				this.filterPartnersList = this.filterPartners();
			});

		this.packageForm.controls.packSettings.controls.isPromo.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				const packPriceControl = this.packageForm.controls.packSettings.controls.packPrice;
				if ( value ) {
					packPriceControl.disable();
					packPriceControl.setValue(0);
				} else {
					packPriceControl.enable();
					packPriceControl.setValue(packPriceControl.value || null);
				}
			});
	}

	ngOnDestroy(): void {
		this.ratesImportParams.complete();
		this.ngUnsubscribe.next(true);
		this.ngUnsubscribe.complete();
		this.allowedDeactivation.complete();
	}

	filterPartners(
	// ccId: Id<exist>[] | null, subscriptionType: SubscriptionType | null = null
	): Partner<exist>[] {
		const list = this.partnerService.list;
		const ccId = this.subscriptionForm.controls.contractCompanies?.value;
		const subscriptionType = this.subscriptionForm.controls.type?.value;

		let filtered: Partner<exist>[] = ccId === null ? list : list.filter(p => ccId.includes(p.ccId));

		if ( subscriptionType ) {
			filtered = filtered.filter(
				p => subscriptionType === SubscriptionType.RESELLER ? p.isReseller : !p.isReseller
			);
		}

		return filterWildcardData(this.filterPartnersControl.value, filtered, 'name');
	}

	displayCurrencySelected = (id: Id<exist>): string => {
		return this.currencyService.map.get(id ?? 0)?.name ?? '';
	};

	displayGroup = (id: Id<exist>): string => {
		return this.subGroupsService.map.get(id ?? 0)?.name ?? '';
	};

	displayPersonalForSelected: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.partnerService.list.map(i => i.name).join(', ');
		}
		return id.map(i => (this.partnerService.map.get(i ?? 0)?.name ?? '')).join(', ');
	};

	displayCompanySelected: AlarisMultiSelectDisplayWithFn<Id<exist>> = (id: Id<exist>[] | null): string => {
		if ( id === null ) {
			return this.lService.translate('gl.all');
		}
		return id.map(i => (this.ccService.map.get(i ?? 0)?.name ?? '')).join(', ');
	};

	displayBillingModeSelected = (mode: DecisionMode | null): string => {
		return mode ? this.lService.translate('enums.' + mode) : '';
	};

	displayType = (type: SubscriptionType | null): string => {
		return type ? this.lService.translate('enums.' + type) : '';
	};

	close(): void {
		this.router.navigate(this.authGuard.parentRoute.getValue()?.url
			.map(item => item.path) ?? []);
	}

	save(): void {
		const subscription: Subscription = {
			...this.subscription,
			...this.subscriptionForm.value,
			...this.packageForm.getRawValue(),
			personalFor: this.subscriptionForm.get('personalFor')?.value === null ?
				this.partnerService.list.map(i => i.id) :
				this.subscriptionForm.get('personalFor')?.value,
			contractCompanies: this.subscriptionForm.get('contractCompanies')?.value === null ?
				this.ccService.list.map(i => i.id) :
				this.subscriptionForm.get('contractCompanies')?.value
		} as Subscription;

		if (
			[SubscriptionType.PLAN, SubscriptionType.RESELLER, null]
				.includes(this.subscriptionForm.controls.type.value)
		) {
			['messagesTotal', 'packSettings', 'availableCountryNetList'].forEach(f => {
				delete subscription[f];
			});
		}

		if ( this.subscriptionForm.controls.trafficType.value !== TrafficType.VIBER ) {
			delete subscription.settings;
		}

		this.subscriptionService.update(subscription)
			.pipe(
				switchMap((response: UpdateResponse<Subscription<exist>[]>) => {
					if (
						response.Data
						&& [SubscriptionType.PLAN, SubscriptionType.RESELLER].includes(response.Data[0]?.type)
						&& this.ratesImportParams.getValue()
						&& !this.subscription.id
					) {
						const params: RatesImportRequest = {
							Data: {
								...this.ratesImportParams.getValue()!.Data,
								subscriptionId: response.Data[0].id
							}
						};
						return this.ratesService.import(params);
					}
					return of(response);
				}),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(() => this.close());

	}

	refreshImportHistory(): void {
		if ( this.subscription.id ) {
			const params: RPCRequestParams = {
				Filters: [
					{
						Field: 'subscriptionId',
						Type: FilterType.EXACT,
						Value: this.subscription.id
					},
					{
						Field: 'status',
						Type: FilterType.IN,
						Value: [ImportStatus.NEW, ImportStatus.PENDING]
					}
				],
				Paging: { Skip: 0, Take: 10 }
			};
			this.importHistoryService.read(params)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.activeImportHistory = resp.Data ?? [];
				});
		}
	}

	updateActiveImportHistory(importHistory: RatesImportHistory[]): void {
		this.activeImportHistory = importHistory.filter(history =>
			history.status === ImportStatus.PENDING || history.status === ImportStatus.NEW
		);
	}

	export(item: RatesImportHistory): void {
		this.allowedDeactivation.next(false);
		this.fileService.export(item.file.id, item.file.name)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.allowedDeactivation.next(true);
			});
	}

	createGroup(): void {
		this.editPanel.open(EditSubscriptionGroupComponent, EditPanelWidth.SM, {
			group: new SubscriptionGroupEntity(null)
		});
	}

	openImportPanel(): void {
		this.editPanel.open<RatesImportPanelComponent, IdObject, RatesImportRequest | undefined>(
			RatesImportPanelComponent,
			EditPanelWidth.XL,
			{
				id: this.subscriptionForm.controls.id.value || null
			}
		)
			.pipe(
				switchMap((result: RatesImportRequest | undefined) => {
					this.ratesImportParams.next(result);
					if ( result && this.subscription.id ) {
						const params: RatesImportRequest = {
							Data: {
								...result.Data,
								subscriptionId: this.subscription.id
							}
						};
						return this.ratesService.import(params);
					}
					return of(result);
				}),
				takeUntil(this.ngUnsubscribe))
			.subscribe(() => this.subscriptionForm.markAsDirty());
	}
}
