import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { NetworkDialCode } from '@campaign-portal/namespace/entities/mccmnc/specs';

import {
	filterWildcardFn,
	LocalTableUtils,
	TableEntityField,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { APCustomValidators } from '@helpers/validators/custom-validators';

@Component({
	selector: 'app-network-dial-codes',
	templateUrl: './network-dial-codes.component.html',
	styleUrls: ['./network-dial-codes.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetworkDialCodesComponent implements OnInit, OnChanges {
	@Input() codes: NetworkDialCode[] = [];
	@Input() countryDialCode: number | null = null;
	@Input() editable = true;
	@Output() readonly codesChange = new EventEmitter<NetworkDialCode[]>();

	readonly errors = [{ key: 'duplicate', value: 'errors.duplicate' }];
	readonly dialCodeControl = new FormControl<number | null>(null, Validators.required);
	showForm = false;

	readonly headers: TableEntityField[] = [
		new TableEntityField({
			id: 0,
			name: 'columns.networkDialCode',
			variable: 'dialCode',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		})
	];
	readonly filters: TableFiltersIndicator = new Map()
		.set('dialCode', { enabled: true, value: undefined });

	readonly sorting: TableSortIndicator = new Map()
		.set('dialCode', { enabled: true, value: SortDirection.EMPTY });

	tRows: NetworkDialCode[] = [];
	private tRowsMap: Map<number, NetworkDialCode> = new Map();

	constructor() {
	}

	get tRowsDefault(): NetworkDialCode[] {
		return Array.from(this.tRowsMap.values());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.countryDialCode ) {
			this.dialCodeControl.setValidators([
				Validators.pattern(
					new RegExp(this.countryDialCode ? `^${this.countryDialCode}.*$` : '')
				),
				Validators.required,
				APCustomValidators.hasDialCodeDuplicate(this.tRowsMap)
			]);
			if ( this.countryDialCode ) {
				this.dialCodeControl.setValue(this.countryDialCode);
			} else {
				this.dialCodeControl.reset();
			}
			this.dialCodeControl.updateValueAndValidity();
		}
	}

	ngOnInit(): void {
		this.tRowsMap = new Map(this.codes
			.filter((networkCode) => networkCode.dialCode !== null)
			.map((networkCode) => [networkCode.dialCode as number, networkCode]));
		this.tRows = this.tRowsDefault;
		this.dialCodeControl.addValidators(APCustomValidators.hasDialCodeDuplicate(this.tRowsMap));
	}

	changeFilters($event: TableFiltersIndicator): void {
		this.applyFilter($event);
		this.applySorting(this.sorting);
	}

	applyFilter($event: TableFiltersIndicator): void {
		this.tRows = this.tRowsDefault;
		for ( const [variable, filter] of $event.entries() ) {
			if ( filter?.value && filter.enabled ) {
				switch (variable) {
					case 'dialCode': {
						const code = (filter.value as string).trim();
						this.tRows = this.tRows.filter((networkCode) => {
							return filterWildcardFn(networkCode.dialCode?.toString() ?? '', code);
						});
					}
				}
			}
		}
	}

	applySorting($event: TableSortIndicator): void {
		for ( const [variable, sort] of $event.entries() ) {
			if ( sort?.value && sort.enabled ) {
				this.tRows = this.tRows.sort((a, b) => {
					let first: unknown;
					let second: unknown;
					switch (variable) {
						case 'dialCode':
							first = a.dialCode?.toString(10);
							second = b.dialCode?.toString(10);
					}

					return LocalTableUtils.sortComparisonFn(sort.value ?? SortDirection.EMPTY, first, second);
				});
			}
		}
	}

	addNetworkCode(): void {
		const dialCode = this.dialCodeControl.value;
		if ( dialCode !== null ) {
			this.tRowsMap.set(dialCode, { id: null, dialCode });
			this.applyFilter(this.filters);
			this.applySorting(this.sorting);
			this.dialCodeControl.setValue(this.countryDialCode);
			this.codesChange.emit(this.tRowsDefault);
		}
	}

	removeNetworkCode(dialCode: number): void {
		this.tRowsMap.delete(dialCode);
		this.applyFilter(this.filters);
		this.applySorting(this.sorting);
		this.codesChange.emit(this.tRowsDefault);
	}
}
