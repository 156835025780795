<alaris-card>
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && isFiltersApplied" class="table-management">
		<div [formGroup]="filtersGroup" class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-input [label]="'fc.searchTask' | translate"
						  class="control"
						  data-testid="searchByTask"
						  formControlName="name" size="md">
				<alaris-icon (click)="filtersGroup.controls.name.reset(null)"
							 *ngIf="filtersGroup.controls.name.value"
							 data-testid="clearSearchByTaskBtn"
							 extra
							 name="icon-close"
							 style="cursor: pointer">
				</alaris-icon>
			</alaris-input>
			<alaris-date-filter [label]="'fc.chooseDate' | translate"
								[stateAddonEnabled]="{reset: true, state: false}"
								class="control" data-testid="creationDateFilter"
								formControlName="creationDate" size="md">
			</alaris-date-filter>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !isFiltersApplied) {
			<app-empty-table type="edr-export-task"></app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [dblClickRowAction]="openTaskDetailDialog.bind(this)"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{name, status, creationDate, size, fileId}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #name [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="task-column">
		<div class="icon">
			<alaris-icon [name]="getName(data.status)"></alaris-icon>
		</div>
		<b class="id">{{ data.name || 'Task-' + data.id }}</b>
		<alaris-copy [data]="data.name || 'Task-' + data.id"></alaris-copy>
		<span class="period">
			{{ 'gl.period' | translate }}:
			<ng-container
				*ngTemplateOutlet="edrDate; context: getPeriod(data.filters)"></ng-container>
		</span>
		<div class="fields">
			{{ 'gl.fields' | translate }}: {{ edrFields.fields(data.fields | slice:0: 4) }}
			<alaris-tag (click)="openTaskDetailDialog(data)"
						*ngIf="data.fields.length > 4"
						[rounded]="true"
						class="pointer" data-testid="openTaskDetailsBtn">
				+{{ data.fields.length - 4 }}
			</alaris-tag>
		</div>
		<alaris-icon
			*ngIf="data.status === EdrExportStatus.FAILED ||
					   (data.status === EdrExportStatus.SENT && data.description)"
			[alarisTooltip]="data.description ?? ''"
			class="note" name="icon-warning-circle">
		</alaris-icon>
	</div>
</ng-template>

<ng-template #status [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="status-column">
		<div [style.background]="getColor(data.status)" class="dot"></div>
		{{ 'enums.' + data.status | translate }}
	</div>
</ng-template>

<ng-template #creationDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ (data.creationDate | date: 'd MMM y, HH:mm') || '-' }}
</ng-template>

<ng-template #size [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.file?.size ? formatBytes(data.file?.size ?? 0) : '-' }}
</ng-template>

<ng-template #fileId [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<button (click)="download(data.file.id, data.file.name)"
			*ngIf="data.file && data.file.id"
			[attr.data-testid]="'downloadTaskBtn/' + data.id" alaris-button bType="primary" size="md">
		<alaris-icon name="icon-import"></alaris-icon>
		{{ 'gl.download' | translate }}
	</button>
	<div *ngIf="!data.file?.id" class="fileId-column">
		-
		<alaris-icon (click)="cancel(data)" *ngIf="data.status === EdrExportStatus.WAITING"
					 [alarisTooltip]="'actions.cancel' | translate"
					 [attr.data-testid]="'cancelTaskBtn/' + data.id" class="cancel-icon"
					 name="icon-error">
		</alaris-icon>
		<alaris-icon (click)="restart(data)"
					 *ngIf="data.status === EdrExportStatus.FAILED || data.status === EdrExportStatus.CANCELLED"
					 [alarisTooltip]="'statistics.restartTask' | translate"
					 [attr.data-testid]="'restartTaskBtn/' + data.id" class="cancel-icon"
					 name="icon-arrow-clock-wise">
		</alaris-icon>
	</div>
</ng-template>

<ng-template #edrDate let-end=End let-start=Start>
	<ng-container *ngIf="start === null && end === null; else date">
		{{ 'gl.allPeriod' | translate }}
	</ng-container>
	<ng-template #date>
		{{ (start | date: 'd MMM y') ?? ('calendar.before' | translate) }}
		-
		{{ (end | date: 'd MMM y') ?? ('calendar.after' | translate) }}
	</ng-template>
</ng-template>
