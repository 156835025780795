import { Injectable } from '@angular/core';

import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';

import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { MCCMNC } from '@campaign-portal/namespace/entities/mccmnc/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { MccmncService } from './mccmnc.service';
import { MccmncPipe } from './mccmnc.pipe';

@Injectable()
export class MccmncTableService extends LocalTableService<MCCMNC<exist>> {

	constructor(
		service: MccmncService,
		stateService: AlarisTableStateService,
		private readonly pipe: MccmncPipe
	) {
		super(service, stateService);
	}

	applyFilters(result: ReadResponse<MCCMNC<exist>[]>, params: RPCRequestParams): ReadResponse<MCCMNC<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {
				switch (filter.Field) {
					case 'mcc':
						data = data.filter((item) => {
							const value = this.pipe.transform(item.mcc);
							return filterWildcardFn(value, filter.Value as string);
						});
						break;
					case 'country':
						data = data.filter((item) => {
							const value = item.country;
							return filterWildcardFn(value, filter.Value as string);
						});
						break;
					case 'mnc':
						data = data.filter((item) => {
							const value = this.pipe.transform(item.mnc);
							return filterWildcardFn(value || '', filter.Value as string);
						});
						break;
					case 'network':
						data = data.filter((item) => {
							const value = item.network;
							return filterWildcardFn(value || '', filter.Value as string);
						});
						break;
					case 'countryDialCode':
						data = data.filter((item) => {
							const value = item.countryDialCode?.toString();
							return filterWildcardFn(value || '', filter.Value as string);
						});
						break;
					case 'lastUpdated':
						data = LocalTableUtils.filterDateRange(filter, data as unknown as Record<string, string>[]) as unknown as MCCMNC<exist>[];
						break;
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(result: ReadResponse<MCCMNC<exist>[]>, params: RPCRequestParams): ReadResponse<MCCMNC<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'mcc':
							first = a.mcc;
							second = b.mcc;
							break;
						case 'country':
							first = a.country;
							second = b.country;
							break;
						case 'mnc':
							first = a.mnc;
							second = b.mnc;
							break;
						case 'network':
							first = a.network;
							second = b.network;
							break;
						case 'countryDialCode':
							first = a.countryDialCode;
							second = b.countryDialCode;
							break;
						case 'lastUpdated':
							first = a.lastUpdated ? new Date(a.lastUpdated) : a.lastUpdated;
							second = b.lastUpdated ? new Date(b.lastUpdated) : b.lastUpdated;
							break;
						default:
							break;
					}
					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}
}
