@if (showImg) {
	<div [class]="type" class="img"></div>
}
@if (title) {
	<div class="title">{{ title | translate }}</div>
}
@if (subTitle) {
	<div class="sub-title">
		{{ subTitle | translate }}

		@if (subTitleLink) {
			<alaris-link (click)="subTitleLinkClicked()"
						 [underline]="false" data-testid="emptyTableLink" type="accent">
				{{ subTitleLink | translate }}
			</alaris-link>
		}
	</div>
}
<div class="buttons">
	@if (buttonText) {
		<button (click)="clicked()" [disabled]="disabled"
				alaris-button bType="primary" data-testid="emptyTableBtn" size="lg">
			<alaris-icon [name]="icon" class="in-primary"></alaris-icon>
			{{ buttonText | translate }}
		</button>
	}
	@if (optionalButtonText) {
		<button (click)="optionalButtonClicked()"
				[disabled]="disabled" alaris-button bType="outline" data-testid="emptyTableAdditionalBtn" size="lg">
			{{ optionalButtonText | translate }}
		</button>
	}
</div>

