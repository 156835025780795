import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, interval } from 'rxjs';

import {
	AlarisComplexTableComponent,
	AlarisEditPanelService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	SharedTemplatesService,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TableFiltersIndicator } from '@campaign-portal/components-library/lib/table';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { TransactionHistory } from '@campaign-portal/namespace/entities/transaction-history/specs';
import { OperationType, TrafficType } from '@campaign-portal/namespace/common/enums';
import { FilterType, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { Partner } from '@campaign-portal/namespace/entities/partners/specs';
import { exist } from '@campaign-portal/namespace/common/id';

import { TransactionFieldsService } from './transaction-fields.service';
import { TransactionsService } from './transactions.service';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PartnersService } from '../../partners/partners.service';
import { OwnerService } from '@helpers/services/owner.service';
import { ContractCompaniesService } from '../../settings/contract-companies/contract-companies.service';
import { CurrencyService } from '@helpers/services/currency.service';
import { PartnerEntity } from '@helpers/types/app.classes-interfaces';

@Component({
	selector: 'app-transaction-history',
	templateUrl: './transaction-history.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./transaction-history.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TransactionFieldsService]
})
export class TransactionHistoryComponent extends AlarisComplexTableComponent<TransactionHistory> implements OnInit, OnDestroy {

	readonly OperationType = OperationType;
	// isNoteVisible = true;
	readonly tabsFilter = [
		{ label: 'tb.allTransactions', value: null },
		{ label: 'enums.' + OperationType.CAMPAIGN, value: OperationType.CAMPAIGN },
		{ label: 'enums.' + OperationType.PACK_PURCHASE, value: OperationType.PACK_PURCHASE },
		{ label: 'enums.' + OperationType.BALANCE_TOP_UP, value: OperationType.BALANCE_TOP_UP },
		{ label: 'enums.' + OperationType.PAYMENT, value: OperationType.PAYMENT },
		{ label: 'enums.' + OperationType.API, value: OperationType.API }
	];

	readonly selectedTabFilter = new FormControl<OperationType | null>(this.tabsFilter[0].value);

	override readonly id = 'transaction-history';
	override filters: TableFiltersIndicator = new Map()
		.set('msgFromPack', { enabled: false })
		.set('operationType', { enabled: false });

	override sorting: TableSortIndicator = new Map()
		.set('id', { enabled: true, value: SortDirection.DESC });

	constructor(
		public readonly transactionsService: TransactionsService,
		public override readonly fieldsService: TransactionFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		public override readonly stateService: AlarisTableStateService,
		public readonly sharedTemplates: SharedTemplatesService,
		private readonly ls: AlarisLanguageService,
		private readonly cu: ChannelUtilsService,
		private readonly partnersService: PartnersService,
		private readonly profile: AlarisProfileService,
		private readonly ccService: ContractCompaniesService,
		private readonly curService: CurrencyService,
		private readonly isOwner: OwnerService
	) {
		super(
			transactionsService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.INTERNAL,
			DEFAULT_BUTTONS,
			stateService
		);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.selectedTabFilter.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this._applyFilter(this.filters));

		interval(30000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.load(false);
		});
	}

	override load(showLoading = true): void {
		if ( showLoading ) {
			this.loading$.next(true);
		}
		this.dataService.read(this.readParams)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((resp) => {
				this.tRows = resp.Data;
				this.total = resp.Total;
				if ( showLoading ) {
					this.loading$.next(false);
				}
				this.cd.markForCheck();
			});
		this.cd.markForCheck();
	}

	override dblClickRowAction(tHistory?: TransactionHistory): void {
		if ( tHistory?.operationType === OperationType.CAMPAIGN ) {
			return this.openCampaignDetailsPanel(tHistory);
		}
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		const partnerList$ = new BehaviorSubject<Partner<exist>[]>([]);
		this.setRefBooksInFields({
			partnerId: { list$: partnerList$, loading$: this.partnersService.loading$ }
		});
		this.partnersService.list$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((partners) => {
				const defaultCCId = this.ccService.list[0]?.id ?? 0;
				const defaultCurrencyId = this.curService.list[0].id ?? 0;
				const resellerPartner = new PartnerEntity(
					this.profile.user.partnerId,
					defaultCCId,
					defaultCurrencyId,
					this.ls.translate('finance.myPartner')
				);
				partnerList$.next(this.isOwner.is ? partners : [resellerPartner, ...partners]);
			});
	}

	override applyFilter($event: TableFiltersIndicator): void {
		super.applyFilter($event);
		if ( this.selectedTabFilter.value ) {
			this.readParams.Filters?.push({
				Field: 'operationType',
				Type: FilterType.EXACT,
				Value: this.selectedTabFilter.value
			});
		}
	}

	openCampaignDetailsPanel(transactionHistory?: TransactionHistory): void {
		this.editPanel.open(TransactionDetailsComponent, EditPanelWidth.SM, {
			transactionHistory
		});
	}

	getIconName(operationType: OperationType, channels: TrafficType[]): string {
		switch (operationType) {
			case OperationType.BALANCE_TOP_UP:
			case OperationType.PAYMENT:
				return 'icon-arrow-line-down';
			case OperationType.CAMPAIGN:
			case OperationType.PACK_PURCHASE:
				return channels?.length > 1
					? 'icon-multi-plan'
					: channels.at(0)
						? this.cu.icon(channels.at(0))
						: '';
			case OperationType.API:
				return 'icon-api';
			default:
				return 'icon-multi-plan';
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.completeRefBooksInFields(['partnerId']);
	}
}
