import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { EdrExport } from '@campaign-portal/namespace/entities/edr/specs';
import { EdrExportStatus } from '@campaign-portal/namespace/common/enums';
import { NamedObject } from '@campaign-portal/namespace/common/namedObject';
import { Filter, FilterType } from '@campaign-portal/namespace/common/rpc.params';

import {
	AlarisBalanceService,
	AlarisConfigService,
	AlarisDialogService,
	AlarisFilesService,
	ChannelUtilsService,
	ChartUtilsService
} from '@campaign-portal/components-library';

import { CanDeactivateComponent } from '@helpers/can-deactivate/can-deactivate.component';
import { CanDeactivateGuardService } from '@helpers/can-deactivate/can-deactivate.guard';
import { EdrExportFieldsService } from '../../edr-export/edr-export-fields.service';
import { EdrExportTaskService } from '../edr-export-task.service';

export interface TaskDetailsDialogData {
	task: EdrExport<exist>;
}

@Component({
	selector: 'app-task-details-dialog',
	templateUrl: './task-details-dialog.component.html',
	styleUrls: [
		'../../../../../assets/templates/dialog.component.scss',
		'./task-details-dialog.component.scss'
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailsDialogComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
	readonly EdrExportStatus = EdrExportStatus;
	readonly task!: EdrExport<exist>;
	showParameters = false;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);
	protected readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(DIALOG_DATA) public readonly data: TaskDetailsDialogData,
		public readonly edrFields: EdrExportFieldsService,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly edrExportTaskService: EdrExportTaskService,
		private readonly filesService: AlarisFilesService,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService,
		public readonly bs: AlarisBalanceService,
		public readonly cu: ChannelUtilsService,
		public readonly chartUtilsService: ChartUtilsService,
		public readonly alarisConfig: AlarisConfigService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.task = data.task;
	}

	get disabled(): boolean {
		return this.loading$.getValue();
	}

	name(list: NamedObject[]): string[] {
		return list.map(i => i.name);
	}

	ngOnInit(): void {
		this.edrFields.map$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(() => {
				this.cd.detectChanges();
			});

		const edrDateFilter = this.task.filters.find(f => f.Field === 'edrDate');
		if ( !edrDateFilter ) {
			const edrDate: Filter = {
				Field: 'edrDate',
				Type: FilterType.BETWEEN,
				Value: {
					Start: null,
					End: null
				}
			};
			this.task.filters.unshift(edrDate);
		}
	}

	getColor(status: EdrExportStatus): string {
		const map: { [key in EdrExportStatus]: string } = {
			[EdrExportStatus.WAITING]: 'var(--as-BgInfo)',
			[EdrExportStatus.SENT]: 'var(--as-BgPositive)',
			[EdrExportStatus.CANCELLED]: 'var(--as-BgTertiaryExtra)',
			[EdrExportStatus.READY]: 'var(--as-BgPositive)',
			[EdrExportStatus.FAILED]: 'var(--as-BgNegative)'
		};
		return map[status];
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.task.status) {
			case EdrExportStatus.CANCELLED:
			case EdrExportStatus.FAILED:
				caller = this.edrExportTaskService.restart(this.task);
				break;
			case EdrExportStatus.WAITING:
				caller = this.edrExportTaskService.cancel(this.task);
				break;
			default:
				caller = this.filesService.export(this.task.file?.id || '');
				break;

		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
