<alaris-card *alarisLet="(isFiltersApplied || !!selectedTabFilter.value) as hasFilter">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
			<alaris-tabs-button [formControl]="selectedTabFilter" [tabs]="tabsFilter"
								data-testid="transactionTypeTabs">
			</alaris-tabs-button>
			<button (click)="editTableFilter()" alaris-button bType="outline" data-testid="editFilterBtn" size="lg">
				<alaris-icon class="in-outline" name="icon-filter-vertical"></alaris-icon>
				{{ enableButtons.filters.title | translate }}
				<div *ngIf="isFiltersApplied" class="indicator"></div>
			</button>
		</div>
		<div class="right">
			<button (click)="load()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
		</div>
	</div>
	<!--		<div *ngIf="isNoteVisible" class="note">-->
	<!--			<alaris-icon name="icon-info"></alaris-icon>-->
	<!--			{{'finance.note' | translate}}-->
	<!--			<alaris-icon (click)="isNoteVisible = false" class="close" name="icon-close"></alaris-icon>-->
	<!--		</div>-->
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table type="transactions"></app-empty-table>
		} @else {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [dblClickRowAction]="dblClickRowAction.bind(this)"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{id, amount, msgFromPack, creationDate, partnerId, operationType, description}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #id [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="id-column">
		<alaris-icon [class.adjustment]="data.operationType === OperationType.ADJUSTMENT"
					 [class.balance-top-up]="data.operationType === OperationType.BALANCE_TOP_UP ||
											 data.operationType === OperationType.PAYMENT"
					 [name]="getIconName(data.operationType, data.channels)">
		</alaris-icon>

		{{ data.id }}
	</div>
</ng-template>

<ng-template #amount [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<ng-container *ngTemplateOutlet="sharedTemplates.get('amount');
				context: {$implicit: data.amount}">
	</ng-container>
</ng-template>

<ng-template #msgFromPack [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.msgFromPack ? data.msgFromPack : null }}
</ng-template>

<ng-template #creationDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.creationDate | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #partnerId [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<ng-container *ngTemplateOutlet="sharedTemplates.get('partnerId');
				context: {$implicit: data.partnerId}">
	</ng-container>
</ng-template>

<ng-template #operationType [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ 'enums.' + data.operationType | translate }}
</ng-template>

<ng-template #description [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="description-column">
		<div class="description">{{ data.description }}</div>
		<span (click)="openCampaignDetailsPanel(data)"
			  *ngIf="data.operationType === OperationType.CAMPAIGN || data.operationType === OperationType.API"
			  [attr.data-testid]="'openDetailsPanelBtn/' + data.id" class="details">
			{{ 'gl.showDetails' | translate }}
		</span>
	</div>
</ng-template>


