<alaris-card size="auto">
	<div class="table-management">
		<div class="left">
			<span class="total" data-testid="total">{{ totalTitle | translate }}: {{ total }}</span>
		</div>
		<div class="right">
			<button (click)="refresh()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refresh' | translate }}
			</button>
			<button (click)="import()"
					*ngIf="withImport" alaris-button bType="primary" data-testid="importBtn" size="lg">
				<alaris-icon name="icon-import"></alaris-icon>
				{{ 'rates.importRates' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (!isFiltersApplied && total === 0) {
			<app-empty-table type="subscription-rates"></app-empty-table>
		} @else if (isFiltersApplied || total > 0) {
			<alaris-table (filterChange)="_applyFilter($event)"
						  (sortingChange)="_applySorting($event)"
						  [colSpan]="false"
						  [expandable]="true"
						  [expandedRowExtraData]="{currencyCode: bs.currencyCode}"
						  [expandedRowTemplate]="expandedRowTemplate"
						  [filters]="filters"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [tSettings]="tSettingsModified"
						  [templates]="{ mcc, mnc, country, network, activeFrom, activeTill, price}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #mcc [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.country.mcc | mccmnc }}
</ng-template>

<ng-template #mnc [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.network?.mnc | mccmnc }}
</ng-template>

<ng-template #country [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="country-column">
		<div class="country">{{ data.country.name }}</div>
		<ng-container *ngIf="data.history?.length">
			<div class="history">({{ data.history?.length }})</div>
			<div class="arrow">
				<alaris-icon aria-expanded="true" name="icon-caret-right" style="cursor: pointer"></alaris-icon>
			</div>
		</ng-container>
	</div>
</ng-template>

<ng-template #network [alarisCellCtx]="tRows[0]" let-data="data">
	{{ data.network?.name || ('endpoints.allNetworks' | translate) }}
</ng-template>

<ng-template #activeFrom [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeFrom | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #activeTill [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.activeTill | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #price [alarisCellCtx]="tRows[0]" let-data="data">
	<div class="price-column">
		{{ data.price | currency: bs.currencyCode:'symbol':'1.0-5' }}
	</div>
</ng-template>

<ng-template #expandedRowTemplate [alarisExpandedCellCtx]="tRows[0]" let-element let-extra=extra>
	<ng-container *alarisLet="element.price as currentPrice">
		<tr *ngFor="let history of element.history; let last=last"
			[class.last]="last"
			[style.font-weight]="history.type === RateStatus.CURRENT ? 700 : 500"
			[style.opacity]="history.type === RateStatus.CLOSED ? .5 : 1"
			class="expanded-row">
			<td>
				<alaris-tag>{{ 'enums.' + history.type | translate }}</alaris-tag>
			</td>
			<td></td>
			<td>{{ history.activeFrom | date: 'd MMM y, HH:mm' }}</td>
			<td>{{ history.activeTill | date: 'd MMM y, HH:mm' }}</td>
			<td [style.color]="history.price > currentPrice ?
									'var(--as-TextNegative)' : history.price < currentPrice ?
										'var(--as-TextPositive)' : 'var(--as-TextPrimary)'"
				class="price-column">
				{{ history.price | currency: bs.currencyCode:'symbol':'1.0-5' }}
			</td>
		</tr>
	</ng-container>
</ng-template>

